
@viewport{
  width: extend-to-zoom;
  zoom: 1.0;
}

@-ms-viewport{
  width: extend-to-zoom;
  zoom: 1.0;
}

body {
  color: #000000;
  font-family: 'Commissioner', sans-serif;
  font-style: normal;
  font-weight: 300;
  animation: fadeIn ease 2s;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;
  -o-animation: fadeIn ease 2s;
  -ms-animation: fadeIn ease 2s;
}

.show {
  display: block;
  animation: fadeIn ease 2s;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;
  -o-animation: fadeIn ease 2s;
  -ms-animation: fadeIn ease 2s;
}

.hide {
  display: none;
  animation: fadeOut ease 2s;
  -webkit-animation: fadeOut ease 2s;
  -moz-animation: fadeOut ease 2s;
  -o-animation: fadeOut ease 2s;
  -ms-animation: fadeOut ease 2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


/* ----- HEADER ----- */

header {
  background-color: #fff;
  border-bottom: 1px solid #d9d9d9;
  box-sizing: border-box;
  display: flex;
  height: 2.5em;
  padding: 0.625em;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

#header-left {
  float: left;
  text-align: left;
  width: 50%;
}

#header-right {
  color: #545454;
  float: left;
  margin: 0.125em 0;
  text-align: right;
  width: 50%;
}

header svg {
  fill: #545454;
}

button {
  background-color: transparent;
  border: none;
  color: #5eb1cf;
  display: inline-block;
  font-weight: 400;
  margin: 0 auto;
  outline: none;
  padding: 0;
  text-align: center;
  width: 5em;
  -webkit-appearance: none;
}

button.inactive:hover {
  cursor: pointer;
  text-decoration: underline;
}


button.active {
  font-weight: 700;
}


/* ----- MAIN ----- */

main {
  display: flex;
  margin: 0 auto;
  width: 1056px;
}

#left {
  border-right: 1px solid #d9d9d9;
  display: block;
  float: left;
  padding: 3.125em;
  text-align: center;
  width: 40%;
}

#right {
  display: block;
  float: left;
  padding: 3.125em;
  width: 60%;
}

#intro {
  margin: 2em 0;
}

#contact-bottom, 
#education-bottom,
#certification-bottom {
  display: none;
}

#photo {
  border-radius: 15em;
  height: 15em;
  margin-bottom: 2em;
  opacity: 0.9;
  width: 15em;
}

h1 {
  color: #000000;
  font-size: 2.5em;
  font-weight: 700;
  letter-spacing: 0.04em;
  margin: 0;
  text-transform: uppercase;
}

h2 {
  color: #545454;
  font-size: 1.25em;
  font-weight: 400;
  letter-spacing: 1.5px;
  margin: 5px 0;
}

h3 {
  color: #000000;
  font-size: 1.25em;
  font-weight: 700;
  letter-spacing: 1px;
  margin: 2px 0;
}

#certification h3,
#certification h5,
#certification-bottom h3,
#certification-bottom h5 {
  font-size: 1.1em;
}

h4 {
  color: #5EB1CF;
  font-size: 1.45em;
  font-weight: 700;
  letter-spacing: 2px;
  margin: 1.75em 0 1em;
  text-transform: uppercase;
}

h5 {
  color: #545454;
  font-size: 1.1em;
  font-weight: 300;
  letter-spacing: 1px;
  margin: 0 0 1em;
}

p {
  font-size: 1em;
  line-height: 1.75em;
  margin: 0 0 1.25em;
  text-align: justify;
}

#contact ul,
#contact-bottom ul {
  list-style: none;
  padding: 0;
}

#contact li,
#contact-bottom li {
  color: #000;
  font-size: 1.2em;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 1em;
  text-align: center;
}

ul {
  padding-left: 1.25em;
}

li {
  font-size: 1em;
  line-height: 1.75em;
  text-align: justify;
}

a {
  color: #000000;
  margin-left: 0.75em;
  text-decoration: none;
}

a:hover {
  color: #5EB1CF;
  text-decoration: underline;
}

#contact svg,
#contact-bottom svg {
  position: relative;
  top: 4px;
}

i {
  color: #A5A5A5;
  font-weight: 300;
}

i:hover {
  color: #5EB1CF;
  text-decoration: underline;
}

span {
  text-decoration: line-through;
}

footer {
  color: #545454;
  border-top: 1px solid #d9d9d9;
  font-size: 0.75em;
  line-height: 1.5em;
  padding: 1em;
}


/* ----- TABLET AND RETINA DEVICES ----- */

@media all and (max-width: 1055px) {
  main {
    display: block;
    margin: 0;
    width: 100%;
  }

  #left {
    border-right: none;
    display: block;
    float: none;
    margin: 5em 3em 0 3em;
    padding: 0;
    text-align: left;
    width: auto;
  }

  #intro {
    text-align: center;
  }
  
  #right {
    display: block;
    float: none;
    padding: 0 3em;
    width: auto;
  }

  #photo {
    text-align: center;
  }

  #contact,
  #education,
  #certification {
    display: none;
  }

  #contact-bottom, 
  #education-bottom,
  #certification-bottom {
    display: block;
  }

  #contact-bottom {
    margin-bottom: 5em;
  }

  #contact-bottom li {
    text-align: left;
  }

  footer {
    text-align: center;
  }
}


/* ----- SMALLER MOBILE AND NON-RETINA DEVICES ----- */

@media all and (max-width: 599px) {
  body {
    font-size: 0.75em;
  }

  svg {
    height: 1.389em;
    width: 1.389em;
  }
}


/* ----- THE UPSIDEDOWN ----- */


body.flip {
  background-color: #333;
}

.flip #left {
  border-right: 1px solid #262626;
}

@media all and (max-width: 1055px) {
  .flip #left {
    border-right: none;
  }  
}

body.flip,
.flip h1,
.flip h3,
.flip a {
  color: #fff;
}

.flip h2,
.flip h5,
.flip footer {
  color: #ababab;
}

.flip h4,
.flip button {
  color: #a14e30;
}

.flip header {
  background-color: #333;
  border-bottom: 1px solid #262626;
}

.flip #header-right {
  color: #545454;
}

.flip header svg,
.flip svg {
  fill: #ababab;
}

.flip footer {
  border-top: 1px solid #262626;
}

.flip img {
  content: url(photo2.jpg);
}
